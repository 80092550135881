.itBlock {
    min-height: 28.125rem;
    padding: 3.4375rem 0;

    &_title {
        font-size: 3rem;
        color: #d93533;
    }

    &_line {
        height: .125rem;
        width: 3.75rem;
        margin: 2.1875rem 0;
        border: 0;
        background-color: #999;
    }

    &_card {
        padding: 1rem;
        color: #fff;

        &_block {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 15.625rem;
        }

        &_summary {
            width: 65%;
        }

        &_title {
            font-size: 2.25rem;
        }

        &_btn {
            margin-top: 2.1875rem;
        }
    }

    @media (max-width: 600px) {

        &_title {
            font-size: 1.5rem;
        }
        &_card {
            &_title {
                font-size: 1.5rem;
            }
        }
    }
}