.summary {
    min-height: 28.125rem;

    &_title {
        font-size: 3rem;
        color: #d93533;
    }

    &_line {
        height: .125rem;
        width: 3.75rem;
        margin: 2.1875rem 0;
        border: 0;
        background-color: #999;
    }

    &_detail {
        font-size: 1.5rem;
        color: #333;
        margin-bottom: 4.0625rem;
    }

    &_btn {
        border: .0625rem solid #999;
        padding: 0 3.75rem;
    }

    @media (max-width: 600px) {
        &_title {
            font-size: 1.5rem;
        }

        &_detail {
            font-size: .875rem;
        }
    }
}