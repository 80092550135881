.banner {
    color: #fff;
    min-height: 95vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &_title {
        font-size: 3.5rem;
        margin-top: -2.1875rem;
        margin-bottom: 3rem;
    }

    &_summary {
        font-size: 2.25rem;
    }

    @media (max-width: 600px) {
        min-height: 21.0625rem;
        padding: 0 5%;

        &_title {
            font-size: 1.5rem;
        }

        &_summary {
            font-size: 1.125rem;
        }
    }
}