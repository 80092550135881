.footer {
    background-color: #262626;
    min-height: 7rem;
    border-top: .1875rem solid #d82b28;
    color: #fff;
    &_beian {
        width: .75rem;
        margin-right: .3125rem;
    }
}
