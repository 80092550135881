.itBlock {
    min-height: 28.125rem;
    padding: 3.4375rem 0;

    &_title {
        font-size: 3rem;
        color: #d93533;
    }

    &_line {
        height: .125rem;
        width: 3.75rem;
        margin: 2.1875rem 0;
        border: 0;
        background-color: #999;
    }

    &_summary {
        font-size: 2rem;
        margin-bottom: .9375rem;
    }

    @media (max-width: 600px) {
        &_title {
            font-size: 1.5rem;
        }

        &_summary {
            font-size: 1.125rem;
            margin-bottom: .9375rem;
            font-weight: 500;
        }

        &_cover {
            padding: 0 1rem;
        }
    }
}