.businessBlock {
    &_summary_title {
        font-size: 3rem;
        color: #d93533;
    }

    &_summary_line {
        height: .125rem;
        width: 3.75rem;
        margin: 2.1875rem 0;
        border: 0;
        background-color: #999;
    }

    &_item {
        color: #fff;
        height: 60.625rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &_title {
        font-size: 2rem;
    }

    &_detail {
        font-size: 1rem;
    }

    &_line {
        height: .125rem;
        width: 3.75rem;
        margin: 2.1875rem 0;
        border: 0;
        background-color: #999;
    }

    &_btn {
        margin-top: 2.8125rem;
    }

    @media (max-width: 600px) {
        &_summary_title {
            font-size: 1.5rem;
        }

        &_item {
            height: 23.9375rem;
        }

        &_title {
            font-size: 1.25rem;
        }

        &_detail {
            font-size: .75rem;
        }
    }
}