.itBlock {
    min-height: 28.125rem;
    padding: 3.4375rem 0;

    &_title {
        font-size: 3rem;
        color: #d93533;
    }

    &_line {
        height: .125rem;
        width: 3.75rem;
        margin: 2.1875rem 0;
        border: 0;
        background-color: #999;
    }

    &_cover {
        // height: 19.0625rem;
        width: 100%;
    }

    &_l {
        padding: 0.5rem;
        box-sizing: border-box;
        overflow: hidden;
    }

    &_details {
        height: 24rem;
        padding: 1.5rem;
        box-sizing: border-box;

        &_title {
            font-size: 2rem;
            margin-bottom: 2rem;
        }

        &_summary {
            font-size: 1.5rem;
        }
    }

    @media (min-width: 768px) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    @media (max-width: 600px) {
        &_title {
            font-size: 1.5rem;
        }

        &_l {
            padding: 16px;
        }

        &_details {
            height: auto;

            &_title {
                font-size: 1.125rem;
                font-weight: 500;
            }

            &_summary {
                font-size: .875rem;
            }
        }
    }
}