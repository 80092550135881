body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
  width: .375rem;
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
} /* 滚动条的滑轨背景颜色 */

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: .625rem;
} /* 滑块颜色 */

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
} /* 滑块颜色 */

::-webkit-scrollbar-button {
  background-color: rgba(0, 0, 0, 0);
  height: 0;
} /* 滑轨两头的监听按钮颜色 */

::-webkit-scrollbar-corner {
  background-color: black;
} /* 横向滚动条和纵向滚动条相交处尖角的颜色 */