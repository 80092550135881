.itBlock {
    height: 28.125rem;
    padding: 3.4375rem 0;

    &_title {
        font-size: 3rem;
        color: #d93533;
    }

    &_line {
        height: .125rem;
        width: 3.75rem;
        margin: 2.1875rem 0;
        border: 0;
        background-color: #999;
    }

    &_img_block {
        max-height: 21.875rem;
        overflow: hidden;
        padding: 8px;
    }

    &_item_block {
        & i {
            color: #d93533;
        }
    }

    &_details {
        font-size: 1.2rem;
        line-height: 1.5;
    }

    @media (max-width: 600px) {
        height: 47.1875rem;

        &_title {
            font-size: 1.5rem;
        }

        &_details {
            font-size: .875rem;
        }

        &_item_block {
            padding: 0 24px;
        }
    }
}