.containerDiv {
    margin-top: 1.5625rem;
    padding: 3.4375rem 0;
    box-sizing: border-box;

    @media (max-width: 600px) {
        padding-bottom: 55px;
    }
}

.containerBlock {
    padding: 1rem;

    &_title {
        margin-bottom: .5rem;
    }

    span {
        margin: 0 .25rem;
        color: red;
    }

    &_input {
        width: 100%;
        padding: .5rem;
        box-sizing: border-box;
        background-color: #f3f3f3;
        border: 0;
    }

    &_input:focus {
        outline: 0;
    }

    &_textarea {
        box-sizing: border-box;
        resize: none;
    }
}

.custom_botton {
    border: .0625rem solid #999;
    padding: 0 3.75rem;
    width: 100%;
}
