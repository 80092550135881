.Banner2 {
    color: white;
    height: 31.25rem;

    &_title {
        font-size: 3rem;
    }

    &_detail {
        font-size: 2.25rem;
    }

    @media (max-width: 600px) {
        height: 10.125rem;

        &_title {
            font-size: 1.5rem;
            margin-bottom: .9375rem;
        }

        &_detail {
            font-size: 1.125rem;
        }
    }
}